import { Text, Grid } from '@lemonenergy/lemonpie'
import styled, { css } from 'styled-components'

import LinkWithIcon from '../components/LinkWithIcon'

import Exame from 'public/exame.png'
import OGlobo from 'public/globo.png'
import Valor from 'public/valor.png'
import Card from '~/components/Card'
import Section from '~/components/Section'
import Title from '~/components/Title'

const SectionWrapper = styled(Section)(
  ({ theme: { media, spacing } }) => css`
    ${Title} {
      margin-bottom: ${spacing(9)};
    }

    ${Card} {
      > img {
        margin-bottom: ${spacing(4)};
      }

      > .valor-img {
        margin-bottom: 21px;
      }
      > ${Text} {
        margin-bottom: ${spacing(4)};
        height: 100%;
      }
    }

    ${media.only.xs} {
      ${Card} {
        margin-bottom: ${spacing(3)};

        > .valor-img {
          margin-bottom: ${spacing(4)};
        }
      }
    }
  `,
)

const Media = () => (
  <SectionWrapper variant="neutral.light">
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Title bold={{ xs: true, sm: false }} variant="title100">
            o que falam sobre a Lemon
          </Title>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={4}>
          <Card bordered>
            <img
              alt="Logo da revista Exame"
              src={Exame}
              width="135px"
              height="35px"
              loading="lazy"
            />
            <Text bold variant={{ xs: 'subtitle200', sm: 'title300' }}>
              Lemon: startup da energia verde para PMEs capta R$ 60 milhões com
              Kaszek
            </Text>
            <LinkWithIcon
              name="arrow-right"
              href="https://exame.com/negocios/lemon-energia-startup-da-energia-verde-para-pmes-capta-r-60-milhoes-com-kaszek"
              target="_blank"
              rotateIcon={-45}
              id="exame-article"
            >
              Ler matéria completa
            </LinkWithIcon>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} sm={4}>
          <Card bordered>
            <img
              alt="Logo da revista O Globo"
              src={OGlobo}
              width="135px"
              height="35px"
              loading="lazy"
            />
            <Text bold variant={{ xs: 'subtitle200', sm: 'title300' }}>
              Apoiada pela Ambev, startup de energia limpa Lemon recebe
              investimento de R$ 60 milhões
            </Text>
            <LinkWithIcon
              name="arrow-right"
              href="https://blogs.oglobo.globo.com/capital/post/apoiada-pela-ambev-startup-de-energia-limpa-lemon-recebe-investimento-de-r-60-milhoes.html"
              target="_blank"
              rotateIcon={-45}
              id="oglobo-article"
            >
              Ler matéria completa
            </LinkWithIcon>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} sm={4}>
          <Card bordered>
            <img
              alt="Logo da revista Valor Econômico"
              src={Valor}
              className="valor-img"
              width="105px"
              height="35px"
              loading="lazy"
            />
            <Text bold variant={{ xs: 'subtitle200', sm: 'title300' }}>
              Lemon Energia atrai Kaszek e primeiro cheque da Lowercarbon no
              Brasil
            </Text>
            <LinkWithIcon
              name="arrow-right"
              href="https://pipelinevalor.globo.com/startups/noticia/lemon-energia-atrai-kaszek-e-primeiro-cheque-da-lowercarbon-no-brasil.ghtml"
              target="_blank"
              rotateIcon={-45}
              id="valor-article"
            >
              Ler matéria completa
            </LinkWithIcon>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  </SectionWrapper>
)

export default Media
